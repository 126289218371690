import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';

import { 
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Form,
  Input,
  Button,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Modal,
 } from "reactstrap"
 import Select from "react-select";
 import { IoEllipsisVertical } from 'react-icons/io5';
 import { IoCheckmarkDoneSharp,IoStopSharp, IoPauseSharp,IoPlaySharp } from 'react-icons/io5';
 import SweetAlert from "react-bootstrap-sweetalert";
import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory} from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"
import Pagination from "../../components/Common/Pagination_ansync"
import styles from "../Contacts/Contacts.module.scss"
import { getAgent, getCasosEstados, getCasosEstadosFilter, getCasosFilter, getCasosList, postCasoUpdateContacto, postCasosStatusChange } from "../../helpers/backend";
import {  casosUpdate } from "../../store/actions";
import ModalMateriales from "./ModalMateriales";
import ModalInforme from "./ModalInforme";

const Casos = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [botonActivo, setBotonActivo] = useState("Todas");
  const [modalMateriales, setModalMateriales] = useState(false);
  const [modalInforme, setModalInforme] = useState(false);
  const [params, setParams] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  const toggleDropdown = (idx) => {
    setDropdownOpen(!dropdownOpen);
    setActiveIndex(idx);
  };
  const  [agent, setAgent] = useState ({});
  const getAgentData = async () => {
    const response = await getAgent();
    const data = response.data;
    setAgent(data)
  }
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [upPhoto, setUpPhoto] = useState(false);
  const [agentAssign, setAgentAssign] = useState(false);
  const [contacto, setContacto] = useState([]);
  const [images, setImages] = useState([]);
  const [actionAlert, setActionAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [action, setAction] = useState("");
  const [ticket, setTicket] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [coordenadas, setCoordenadas] = useState("");
  const [ticketId, setTicketId] = useState(null);

  const [casoId, setCasoId] = useState(null);
  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
      let url;
        if (botonActivo === 'Todas') {
          url = `?search=${search}`;
        } else {
          url = `casos_por_estado/?estado=${botonActivo}&search=${search}`;
        }
        const response = await getCasosFilter (url);
        setParams({
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: response.data.results,
        });
    setLoading(false);
  };

  const resetSearch = async() => {
    setLoading(true);
    let url;
    if (botonActivo === 'Todas') {
      url = `?search=`;
    } else {
      url = `casos/casos/casos_por_estado/?estado=${botonActivo}&search=`;
    }
    const response = await getCasosFilter(url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setSearch("");
    setLoading(false);
  };

  const getCasosData = async () => {
    setLoading(true);
    const response = await getCasosList();
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };
  const getCasosPorEstados = async (estado) => {
    setLoading(true);
    const response = await getCasosEstados(estado);
    console.log(response);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
    setBotonActivo(estado);
  };
  const getFilterData = async (url) => {
    setLoading(true);
    const response = await getCasosFilter(url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };
  const getFilterCasosData = async (url) => {
    setLoading(true);
    const response = await getCasosEstadosFilter(botonActivo, url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };

  const handleAction = (action, ticket, id) => {
    setAction(action)
    setTicket(ticket)
    setTicketId(id)
    setActionAlert(true)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
          setCoordenadas ( latitude + " " + longitude )
      });
    } else {
      console.log("La geolocalización no está disponible en este navegador.");
    }
  }
  const actionSuccess = async () =>{
   const response = await postCasosStatusChange(ticketId, {
      accion:action,
      observaciones:observaciones,
      coordenadas: coordenadas
    })
      if(response.status === 200) {
        setShowAlertSuccess(true)
      }
      else {
        setErrorAlert(true)
      }
    setActionAlert(false)
  }
  const handleFotosChange = (fotos) => {
    const image = [];
  
    Array.from(fotos).forEach((foto) => {
      var reader = new FileReader();
      reader.readAsDataURL(foto);
      reader.onload = function () {
        var fotoBase64 = [];
        var base64 = reader.result;
        fotoBase64 = base64.split(",");
        image.push({ image: fotoBase64[1] });
  
        if (image.length === fotos.length) {
          setImages (image)
        }
      };
    });
  };

 const imagesSave = () => {
  dispatch (casosUpdate (ticketId ,{
    images: images
  }))
  setTicketId(null)
  setShowAlertSuccess(true)
  setUpPhoto(false)
  setImages([])
 }
 const agentSave = async () => {
  if (contacto && contacto.length > 0) {
    const response = await postCasoUpdateContacto(ticketId, {
      contacto: contacto
    })
    console.log(response);
    setTicketId(null)
    setContacto([])
    setAgentAssign(false)
    setShowAlertSuccess(true)
  } else {
    setMessageError(true)
  }
  getCasosData()
setMessageError(false)
}

  useEffect(() => {
    getCasosData()
    getAgentData() 

  }, [])

  function Status({ estado }) {
    const estados = {    
      EN_PAUSA: { className: "bg-info", text: "En Pausa" },
      PENDIENTE: { className: "bg-danger", text: "Pendiente" },
      SIN_ASIGNAR: { className: "bg-warning ", text: "Sin Asignar" },
      EN_PROCESO: { background: "#6f42c1 ", text: "En Proceso" },
      FINALIZADO: { className: "bg-success", text: "Finalizado" },
      ASIGNADO: { className: "bg-primary ", text: "Asignado" }
    };
  
    const estadoActual = estados[estado];
  
    return (
      <div className={`badge rounded-pill ${estadoActual.className} font-size-10`} style={{background: estadoActual.background}}>
        <strong>{estadoActual.text}</strong>
      </div>
    );
  }
  console.log(params);

  const handleCloseModal = () => {
    setModalMateriales(false);
    setModalInforme(false);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Casos </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Casos" />
        {modalMateriales ?(

          <ModalMateriales modalMateriales={modalMateriales} 
          handleCloseModal={handleCloseModal} 
          casosId={casoId}
          getCasosData={getCasosData}
          />
        ): null}
        
        {
          modalInforme ? (
            <ModalInforme modalInforme={modalInforme} 
            handleCloseModal={handleCloseModal} 
            casosId={casoId}
            getCasosData={getCasosData}
            />
          ): null
        }
          <Modal
          isOpen={upPhoto}
          centered={true}
          toggle={()=> {setUpPhoto(false); setImages([])}}
          >
            <div className="modal-header">
                <h3 className="modal-title mt-2">
                    Fotos
                  <span className="text-danger">*</span>
                </h3>
                <button
                    type="button"
                    onClick={() => {
                      setUpPhoto(false);
                      setImages([])
                    }}
                    className="btn"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
            <Label for="fotos">Fotos</Label>
              <Input
              type="file"
              className="form-control"
              name="fotos"
              id="fotos"
              multiple
              onChange={(e)=> handleFotosChange(e.target.files)}
              />
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary text-dark"
                onClick={() => { setUpPhoto(false);   setImages([])
                }}
                > Cerrar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={()=> {imagesSave()}}
                >
                    Guardar
              </button>
            </div>
          </div>
          </Modal>
          <Modal
          isOpen={agentAssign}
          centered={true}
          toggle={()=> {setAgentAssign(false); setContacto([])}}
          >
          <div className="modal-header">
              <h3 className="modal-title mt-2">
                  Asignar Agente
                <span className="text-danger">*</span>
              </h3>
              <button
                  type="button"
                  onClick={() => {
                    setAgentAssign(false);
                    setContacto([])
                  }}
                  className="btn"
                  data-dismiss="modal"
                  aria-label="Close"
              >
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div className="modal-body">
          <Label for="contacto">Agente: </Label>
            <Select
              id="contacto"
              name="contacto"
              options={Array.isArray(agent)
                ? agent.map((item) => ({
                    label: item.get_full_name,
                    value: item.id,
                  }))
                : []}
              onChange={(selectedOptions) => {
                const selectedContactData = selectedOptions.map(
                  (option) => option.value
                );
                setContacto(selectedContactData);
              }}
              placeholder={"Seleccione"}
              isMulti={true}
              menuPosition="fixed"
            />
            {messageError ? (
            <p className="text-danger">Debe añadir un agente</p>
            ) : null}

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary text-dark"
              onClick={() => { setAgentAssign(false); setContacto([])
              }}
              > Cerrar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={()=> {agentSave()}}
              >
                  Guardar
            </button>
          </div>
        </div>
          </Modal>
              {actionAlert ? (
                <SweetAlert
                  title= {`¿${action} caso ${ticket} ?`}
                  warning
                  showConfirm={true}
                  confirmBtnText="Si"
                  showCancel={true}
                  cancelBtnBsStyle="danger"
                  cancelBtnText="No"
                  onCancel={()=> setActionAlert(false)}
                  onConfirm={() => actionSuccess()}                  
                >
                  <Label>Observaciones</Label>
                  <Input
                   type="textarea"
                   id="observaciones"
                   name='observaciones'
                   onChange={(e)=> setObservaciones(e.target.value)}
                   />                  
                </SweetAlert>
              ) : null}
              {showAlertSuccess ? (
                <SweetAlert
                  title= 'Hecho!'
                  success
                  showConfirm={false}
                  timeout={2000}
                  onConfirm={() => {getCasosData(); setShowAlertSuccess(false) }}                  
                >                 
                </SweetAlert>
              ) : null}
              {errorAlert ? (
                <SweetAlert
                  title= 'Error!'
                  danger
                  showConfirm={false}
                  timeout={2000}
                  onConfirm={() => {getCasosData(); setErrorAlert(false) }}                  
                >                 
                </SweetAlert>
              ) : null}
          {loading ? (
            <Loader />
          ) : (
            <div>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Link
                        className="btn btn-primary waves-effect waves-light mb-1"
                        to="/casos/create"
                      >
                        Agregar
                      </Link>
                    </Col>
                    <Col md={5}>
                      <Form className="search-box" onSubmit={onSubmit}>
                        <InputGroup>
                          <Input
                            type="text"
                            className="form-control"
                            name="search"
                            placeholder="Caso, nombre, descripcion..."
                            value={search}
                            onChange={onChange}
                          />
                          <i className="bx bx-search-alt search-icon" />
                          <Button color="primary" onClick={resetSearch}>
                            <span className=" bx bx-x fa-lg align-middle"></span>
                          </Button>
                        </InputGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card> 
              {/*BOTONES DE FILTROS*/}
              <Row className="mb-3">
                <Col className="d-flex justify-content-center mb-2">
                <Button color="pink" style={{ boxShadow: botonActivo === "Todas" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none"}} className="btn-sm bg-pink text-white ms-1" onClick={()=> { getCasosData(); setBotonActivo('Todas') }}><b>Todas</b> </Button>
                <Button color="primary"  className="btn-sm ms-1" style={{ boxShadow: botonActivo === "ASIGNADO" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none"}}onClick={() => getCasosPorEstados('ASIGNADO')}><b>Asignado</b> </Button>
                <Button color="warning"  className="btn-sm bg-warning  ms-1" style={{ boxShadow: botonActivo === "SIN_ASIGNAR" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none"}}onClick={() => getCasosPorEstados('SIN_ASIGNAR')}><b>Sin Asignar</b> </Button>
                <Button className="btn-sm btn-danger ms-1" style={{ boxShadow: botonActivo === "PENDIENTE" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none"}}onClick={() => getCasosPorEstados('PENDIENTE')}><b>Pendiente</b> </Button>
                <Button className="btn-sm btn-info ms-1" style={{ boxShadow: botonActivo === "EN_PAUSA" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none"}}onClick={() => getCasosPorEstados('EN_PAUSA')}><b>En Pausa</b> </Button>
                <Button color="#6f42c1" className="btn-sm text-white ms-1" style={{ background:"#6f42c1",  boxShadow: botonActivo === "EN_PROCESO" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none"}}onClick={() => getCasosPorEstados('EN_PROCESO')}><b>En Proceso</b> </Button>
                <Button className="btn-sm btn-success ms-1" style={{ boxShadow: botonActivo === "FINALIZADO" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none"}}onClick={() => getCasosPorEstados('FINALIZADO')}><b>Finalizado</b> </Button>
                </Col>
              </Row>                  
              <div className={`text-center ${styles['card-container']}`}>                
          {params.results &&
            params.results.map((casos, idx) => (
            <>
              <Card className={styles.ticket} style={{border: '0px'}}> 
                <div className={styles["dropdown-container"]} >
                <Dropdown isOpen={dropdownOpen && activeIndex === idx} toggle={() => toggleDropdown(idx)} style={{ zIndex: 1000 }}>
                    <DropdownToggle
                      tag="span"
                      onClick={() => toggleDropdown(idx)}
                      className={`${styles.editMenu}`}
                    >
                      <IoEllipsisVertical size={20} />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem className="text-muted" onClick={() => history.push(`/casos/update/${casos.id}`)}>Editar</DropdownItem>   
                      <DropdownItem className="text-muted" onClick={() => {setTicketId(casos.id); setUpPhoto(true)}}>Subir Fotos</DropdownItem>
                      <DropdownItem className="text-muted" onClick={() => { 
                          setCasoId(casos.id)
                          setModalMateriales(true)}}>Materiales</DropdownItem>
                      <DropdownItem className="text-muted" onClick={() => { 
                          setCasoId(casos.id)
                          setModalInforme(true)}}>Informe</DropdownItem>
                      {casos.estado && casos.estado === "SIN_ASIGNAR" ?  
                      <DropdownItem className="text-muted" onClick={() => {setTicketId(casos.id); setAgentAssign(true)}}>Asignar Agente</DropdownItem> :null}                
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div  onClick={() => history.push(`/casos/view/${casos.id}`)}>
                  <div className={styles["ticket-header"]}>  
                    <p className={`mb-0 ${casos.name.startsWith("Desplazamiento") ? "font-size-13" : "font-size-18"}`}>
                      <b>{casos.name}</b>                    </p>
                      <div className="position-absolute top-0 end-0 me-1">{casos.estado ? <>
                        <b><Status estado={casos.estado} /></b> </>: null} 
                      </div>                                
                  </div>   
                
                  <div className={styles["ticket-body"]} >
                  {casos.total_duracion ?
                    <p className="font-size-12 text-dark d-flex justify-content-center"><b>Tiempo de Finalizacion: </b> {casos.total_duracion}</p>
                    : null}
                    <p className="font-size-15 mt-3 mb-1"><b>{casos.descripcion}</b></p>
                    {Array.isArray(casos.contacto)
                        ?  casos.contacto.map(item => item[0]).join(', ')
                        : []}
                  </div>
                </div>
                {/* Botones de accion */}
                {casos.estado !== "FINALIZADO" ? <>
                  <div className={styles['ticket-fixed-button']}>
                    {casos.estado === "ASIGNADO" || casos.estado === "SIN_ASIGNAR" ?
                      <Button color="primary" className="btn-sm mt-1 " onClick={() => handleAction('ACEPTAR', casos.name, casos.id)}><IoCheckmarkDoneSharp /> Aceptar</Button> : null
                    }
                    {casos.estado === "PENDIENTE" ?
                      <Button className="btn-sm btn-warning mt-1" onClick={() => handleAction('INICIAR', casos.name, casos.id)}><IoPlaySharp /> Iniciar</Button> : null
                    }
                    {casos.estado === "EN_PROCESO" ?
                      <>
                        <Button className="btn-sm btn-info mt-1" onClick={() => handleAction('PAUSAR', casos.name, casos.id)}><IoPauseSharp /> Pausar</Button>
                        <Button className="btn-sm btn-success ms-1 mt-1" onClick={() => handleAction('FINALIZAR', casos.name, casos.id)}><IoStopSharp /> Finalizar</Button>
                        {/* <Button 
                        style={{background: "#6f4f28", color: "white"}}
                        className="btn-sm mt-1" 
                        
                        onClick={() =>{ 
                          setCasoId(casos.id)
                          setModalMateriales(true)}}
                        >
                          
                           Materiales</Button>
                        <Button 
                        style={{background: "#808080", color: "white"}}
                        className="btn-sm mt-1" 
                        
                        onClick={() =>{ 
                          setCasoId(casos.id)
                          setModalInforme(true)}}
                        >
                          
                           Informe</Button> */}
                      </> : null
                    }
                    {casos.estado === "EN_PAUSA" ?
                      <Button color="pink" className="btn-sm bg-pink text-white mt-1" onClick={() => handleAction('REANUDAR', casos.name, casos.id)}><IoPlaySharp /> Reanudar</Button> : null
                    }
                  </div></>: null }
              </Card >
            </>
            ))}
        </div>
            </div>

          )}
          <Pagination
            count={params.count}
            next={params.next}
            previous={params.previous}
            limit={30}
            filter={botonActivo !== 'Todas'? true : null}
            baseUrl={`${botonActivo === 'Todas' ? '/casos/casos/' : '/casos/casos/casos_por_estado/?estado='+ botonActivo }`}
            filterFunction={botonActivo === 'Todas'? getFilterData : getFilterCasosData }
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = (state) => {
  const { error, loading, casos } = state.Casos;
  return { error, loading, casos };
};

export default withRouter(connect(mapStateToProps)(Casos));
